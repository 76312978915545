<template>
  <div class="teams-activity-list-wrapper">
    <Header
      :title="$t('teams.team.discussions.title')"
      :iconPath="require('@/assets/images/icons/team-discussion.svg')"
      :showBackButton="true"
    >
      <template v-slot:header-buttons>
        <Button
          variant="primary"
          :pill="true"
          :disabled="isDisabled === true || isDisabled === 'true'"
          @click="$bvModal.show('add-feed-modal')"
        >
          {{ $t("discussions.post.add") }}
        </Button>
      </template>
    </Header>
    <PaginationV2
      ref="teamsactivitylistwrapper"
      dataComponentPath="components/Teams/PaginationWrappers/TeamsActivityListWrapper.vue"
      emptyStateComponentPath="components/Teams/PaginationWrappers/TeamsActivityListEmptyWrapper.vue"
      :dataFetchURL="getTeamDiscussionsListURL(courseId, discussionTopicId)"
      :authToken="authToken"
      :additionalProps="teamDiscussionAdditionalProps"
    ></PaginationV2>
    <AddPostModal
      :topicId="discussionTopicId"
      @postadded="reloadPosts"
    ></AddPostModal>
  </div>
</template>

<script>
import Header from "./Shared/Header.vue";
import AddPostModal from "./Shared/AddPost.vue";

import TeamsPaginationMixin from "@/components/Teams/PaginationWrappers/mixin.js";
export default {
  mixins: [TeamsPaginationMixin],
  components: { Header, AddPostModal },
  computed: {
    courseId() {
      return this.$route.params.id;
    },
    discussionTopicId() {
      return this.$route.params.activityId;
    },
    isDisabled() {
      return this.$route.query.disabled;
    },
    teamDiscussionAdditionalProps() {
      return {
        discussionTopicId: this.discussionTopicId
      };
    }
  },
  methods: {
    reloadPosts() {
      this.$refs.teamsactivitylistwrapper.reload();
    }
  }
};
</script>
<style lang="scss" scoped>
.teams-activity-list-wrapper {
  text-align: left;
  padding: 60px;
}
</style>
